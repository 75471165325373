import React, { useEffect, useState } from 'react';
import styles from './Configurator.module.scss'
import ThreeDModel from './ThreeDModel/ThreeDModel';
import { Canvas } from '@react-three/fiber';
import CountUp from 'react-countup';
import OrderWindow from './OrderWindow/OrderWindow';

const Configurator = () => {

    const [trailer, setTrailer] = useState('ALH130')
    const [price, setPrice] = useState(0)
    const [optionsPrice, setOptionsPrice] = useState(0)
    const [settings, setSettings] = useState({})
    const [modalIsOpened, setModalIsOpened] = useState(false)

    const orderModal = () => {
        setModalIsOpened(true)
    }
    console.log(modalIsOpened)
    
    const prices = {
        dodatkove: 0,
        oklejanie: 500,
        dostava: 200,
        opieka: 250,
    }

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target
        let newOptionsPrice = optionsPrice;

        if (checked) {
          newOptionsPrice += prices[name];
        } else {
          newOptionsPrice -= prices[name];
        }
        setOptionsPrice(newOptionsPrice);
    }

    useEffect(() => {
        const size = trailer === "ALH130" ? 7999 : 9999;
        setPrice(size + optionsPrice);
    
        if (trailer === 'ALH130') {
            setSettings({
                masa: 850,
                ladownosz: 500,
                calkmasa: 1350
            });
        } else if (trailer === 'ALH240') {
            setSettings({
                masa: 950,
                ladownosz: 1750,
                calkmasa: 2700
            });
        }
    }, [trailer, optionsPrice]);

    const handleChangeTrailer = (event) => {
        const selectedTrailer = event.target.value;
        setTrailer(selectedTrailer);
    };

  return (
    <div className={styles.configurator}>
        {modalIsOpened 
        && 
        <OrderWindow
        price={price}
        trailer={trailer}
        settings={settings}
        setModalIsOpened={setModalIsOpened}
        />}
        <div className={styles.options}>
            <div className={styles.option}>
                <div className={styles.wybierzmodel}>
                    <h2>Model :</h2>
                    <select onChange={handleChangeTrailer} value={trailer} name="Wybierz model" id="">
                        <option>Wybierz model</option>
                        <option value="ALH130">3000x2000x2200 | 1350kg | ALH130</option>
                        <option value="ALH130">4000x2000x2200 | 1350kg | ALH140</option>
                        <option value="ALH240">4000x2000x2200 | 2700kg | ALH240</option>
                        <option value="ALH240">5000x2000x2200 | 2700kg | ALH250</option>
                    </select>
                </div>

                <div className={styles.addition}>
                    <h2>Dodatkowe opcje : </h2>
                    <div>
                        <input 
                            type="checkbox" 
                            id="myCheckbox" 
                            name="dodatkove" 
                            value="checkboxValue"
                            onChange={handleCheckboxChange}
                        />
                        <label for="myCheckbox">Dodatkowe wyposażenie</label>
                    </div>
                    <div>
                        <input 
                            type="checkbox" 
                            id="myCheckbox2" 
                            name="oklejanie" 
                            value="checkboxValue" 
                            onChange={handleCheckboxChange}
                        />
                        <label for="myCheckbox2">Oklejanie</label>
                    </div>
                    <div>
                        <input 
                            type="checkbox" 
                            id="myCheckbox3" 
                            name="dostava" 
                            value="checkboxValue" 
                            onChange={handleCheckboxChange}
                        />
                        <label for="myCheckbox3">Dostawa od drzwi do drzwi</label>
                    </div>
                    <div>
                        <input 
                            type="checkbox" 
                            id="myCheckbox4" 
                            name="opieka" 
                            value="checkboxValue" 
                            onChange={handleCheckboxChange}
                        />
                        <label for="myCheckbox4">Opieka po sprzedaży</label>
                    </div>
                </div>
                <div>

                <div className={styles.settings}>
                    <div>
                        Własna masa: <span><CountUp start={settings.masa - 100} duration={0.5} end={settings.masa} /> kg</span>  
                    </div>
                    <div>
                        Ładowność: <span><CountUp start={settings.masa - 100} duration={0.7} end={settings.ladownosz} /> kg</span>
                    </div>
                    <div>
                        Całkowita masa: <span><CountUp start={settings.masa - 250} duration={1} end={settings.calkmasa} /> kg</span> 
                    </div>
                </div>
            </div>

            </div>



            <div className={styles.bottom_pay}>
                <span style={{margin: "1rem 2rem"}}>Price : <CountUp style={{fontWeight: "bold"}} start={price - 100} duration={0.5} end={price} /> $</span>
                <div className={styles.options_bottom}>
                    <button onClick={orderModal}>Order</button>
                </div>
            </div>
        </div>


        <div className={styles.threejs}>
            <Canvas style={{maxWidth: '100%'}}>
                <color attach='background' args={["#fff"]} />
                <ThreeDModel trailer={trailer} />
            </Canvas>
        </div>
    </div>
  );
};

export default Configurator;