import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const SendForm = ({ price, trailer, settings }) => {
    const form = useRef();
    console.log(trailer)
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_t9g8pjc', 'template_uq40ada', form.current, 'A6Tyeb3csDcUtLpAI')
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );
    };

    return (
        <div>
            <h1>Form:</h1>
            <form ref={form} onSubmit={sendEmail}>
                <div>
                    <label htmlFor="name">Name:</label>
                    <input id="name" name="name" type="text" required />
                </div>
                <div>
                    <label htmlFor="number">Number:</label>
                    <input id="number" name="number" type="tel" placeholder="+48 999 999 999" required />
                </div>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input id="email" name="email" type="email" placeholder="email@alkoro.com" required />
                </div>
                <div>
                    <label htmlFor="address">Address:</label>
                    <input id="address" name="address" type="text" placeholder="ul. Tenczynek 28A" required />
                </div>
                <div>
                    <label htmlFor="comments">Comments:</label>
                    <textarea id="comments" name="comments" placeholder="Коментарі..." rows="4" required></textarea>
                </div>
                <input type="hidden" name="price" value={price} />
                <input type="hidden" name="trailer" value={trailer} />
                <button type="submit">Send</button>
            </form>
        </div>
    );
};

export default SendForm;