import React, { Suspense, useMemo } from 'react';
import { MeshReflectorMaterial, PresentationControls, Stage } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const ThreeDModel = ({ trailer }) => {

    const selectedModel = useLoader(
        GLTFLoader, trailer === 'ALH130' 
        ? './models/TrackSmall.glb' 
        : './models/ALH240.glb');

    return (
            <PresentationControls 
                speed={1.5} 
                global zoom={0.8} 
                polar={[-0.1, Math.PI / 3]}
            >
                <Stage environment="city" intensity={0.6} contactShadow={false} shadows={true}>
                    <Suspense fallback={null}>
                        {selectedModel && <primitive object={selectedModel.scene} />}
                    </Suspense>
                </Stage>
                <mesh rotation={[-Math.PI / 2, 0, 0]} receiveShadow>
                    <planeGeometry args={[0, 170]} />
                    <MeshReflectorMaterial
                        blur={[30, 10]} 
                        resolution={720}
                        mixBlur={0.1} 
                        mixStrength={10} 
                        roughness={1}
                        depthScale={1.2}
                        minDepthThreshold={0.4}
                        maxDepthThreshold={1.4}
                        color="#101010"
                        metalness={0.5}
                        moveCameraAway={20}
                    />
                </mesh>
            </PresentationControls>
    );
};

export default ThreeDModel;