import './App.scss';
import Configurator from './components/Configurator/Configurator';
import Header from './components/Header/Header';

const App = () => {

  return (
    <div className="App">
      <Header />
      <Configurator />
    </div>
  );
}

export default App;
