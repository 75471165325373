import React from 'react';
import styles from './OrderWindow.module.scss'
import CountUp from 'react-countup';
import SendForm from '../SendForm/SendForm';

const OrderWindow = ({price, trailer, settings, setModalIsOpened}) => {

    const exitButton = () => {
        setModalIsOpened(false)
    }

  return (
    <div className={styles.orderWindow}>

        <div className={styles.container}>
        <button onClick={exitButton} className={styles.exitButton}>X</button>
            <div className={styles.containerLeft}>
                <h1>Order :</h1>
                    <div>Price : <CountUp style={{fontWeight: "bold"}} start={price - 100} duration={0.5} end={price} /> $</div>
                    <div>Trailer : <span style={{fontWeight: "bold"}}>"{trailer}"</span></div>
                    <div className={styles.orderDetails}>
                        <h3>Order details : </h3>
                        <span>Własna masa: <CountUp style={{fontWeight: "bold"}} start={price - 100} duration={0.5} end={settings.masa} /> kg</span>
                        <span>Ładowność: <CountUp style={{fontWeight: "bold"}} start={price - 100} duration={0.5} end={settings.ladownosz} /> kg</span>
                        <span>Całkowita masa: <CountUp style={{fontWeight: "bold"}} start={price - 100} duration={0.5} end={settings.calkmasa} /> kg</span>
                    </div>
            </div>

            <div className={styles.containerRight}>
                <SendForm
                price={price}
                trailer={trailer}
                settings={settings}
                 />
            </div>
        </div>
    </div>
  );
};

export default OrderWindow;