import React from 'react';
import styles from './Header.module.scss'
import logo from '../../assets/logo.png'
import instagram from '../../assets/instagram.png'
import facebook from '../../assets/facebook.png'

const Header = () => {
  return (
    <header className={styles.header}>
        <a href="/"><img src={logo} alt="" /></a>

        <div className={styles.header_right}>
            <div className={styles.navbar}>
                <ul>
                    <li>o nas</li>
                    <li>produkty</li>
                    <li>usługi</li>
                    <li>faq</li>
                    <li>kontakt</li>
                </ul>
            </div>

            <div className={styles.links}>
                    <img src={instagram} alt="" />
                    <img src={facebook} alt="" />
            </div>
        </div>

    </header>
  );
};

export default Header;